/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?2779b2235fd2e3862e6940cadb329bde") format("truetype"), url("./blueprint-icons-16.eot?2779b2235fd2e3862e6940cadb329bde#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?2779b2235fd2e3862e6940cadb329bde") format("woff2"), url("./blueprint-icons-16.woff?2779b2235fd2e3862e6940cadb329bde") format("woff");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?4e54c5414d0a11653779905197762db7") format("truetype"), url("./blueprint-icons-20.eot?4e54c5414d0a11653779905197762db7#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?4e54c5414d0a11653779905197762db7") format("woff2"), url("./blueprint-icons-20.woff?4e54c5414d0a11653779905197762db7") format("woff");
}